<template>  
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="updateTaskStatus"/>
        <v-skeleton-loader
        v-if="this.api.isLoading"
        ref="skeleton"
        type="table">
        </v-skeleton-loader>
        <v-row no-gutters v-if="!api.isLoading">
            <v-col class="mx-auto my-6">
                <v-container>
                    <!-- <div class="d-flex justify-start">
                        <v-btn class="mb-3" color="primary" @click="redirectBack">
                            <v-icon>
                                mdi-arrow-left-bold
                            </v-icon>
                        </v-btn>          
                    </div> -->
                    <div v-if="this.$store.getters.getRole!='operation'">
                        <v-data-table
                        :items="task"
                        :search="searchResult"
                        :headers = "headersTask"
                        class="elevation-1 mt-5">
                            
                            <template v-slot:item.created_at="{item}">
                                {{ item.created_at.split('T')[0] }}
                            </template>
    
                            <template v-slot:item.priority="{item}">
                                <span v-if="item.priority=='Urgent'" class="font-weight-bold my-3 red--text" >
                                    {{ item.priority }}
                                </span>
                                <span v-if="item.priority=='Medium'" style="color: #FBB955;" class="font-weight-bold my-3 ">
                                    Mid
                                </span>
                                <span v-if="item.priority=='Low'" class="font-weight-bold my-3 green-text" >
                                    Low
                                </span>
                            </template>
                            <template v-slot:item.pic_name="{item}">
                                <span v-if="item.pic_name!=null">
                                    <!-- {{ item.pic_name }} -->
                                    Personal
                                </span>
                                <span v-else>
                                    {{ (item.role_name=='vision_admin')?"Admin":item.role_name.replaceAll('_',' ') }}
                                </span>
                            </template>
                            <template v-slot:item.task_name="{item}">
                                <a @click="redirectReadTask(item.task_id)" class="text-decoration-none">
                                {{ item.task_name}}
                                </a>
                            </template>
                            <template v-slot:item.index="{item}">
                                {{ (task.indexOf(item))+1 }}
                            </template>
                            <!-- <template v-slot:item.task_status="{item}">
                                <div v-if="loadingUpdateStatus">
                                    <v-progress-circular >
    
                                    </v-progress-circular>
                                </div>
                                <div v-else>
                                    <v-checkbox style="color:green" v-if="item.task_status=='Done'" v-model="statusCheckedTrue" disabled>
                                    </v-checkbox>
                                    <v-checkbox disabled v-else v-model="statusCheckedFalse" @change="updateTaskStatus(item,statusCheckedFalse)">
        
                                    </v-checkbox>
                                </div>
                            </template> -->
                            <template v-slot:top>
                               
                                <v-container>
                                    <v-toolbar flat class="pa-3">
                                        <v-text-field filled dense outlined clearable v-model="searchResult" label="Search">
                
                                        </v-text-field>
                                    </v-toolbar>
                                </v-container>
                            </template>
                        </v-data-table>

                    </div>
                    <div v-else>
                        <v-data-table
                        :items="task"
                        :search="searchResult"
                        :headers = "operationHeadersTask"
                        class="elevation-1 mt-5">
                            
                            <template v-slot:item.created_at="{item}">
                                {{ item.created_at.split('T')[0] }}
                            </template>
                            <template v-slot:item.task_status="{item}">
                                <div v-if="loadingUpdateStatus">
                                    <v-progress-circular indeterminate color="primary">

                                    </v-progress-circular>
                                </div>
                                <div v-else>
                                    <div  v-if="item.task_status=='Done'">
                                        <v-checkbox style="color:green" color="success" @change="validateInput(item,statusCheckedTrue,0 )" v-model="statusCheckedTrue">
                                        </v-checkbox>

                                    </div>
                                    <div v-if="item.task_status=='To do'">
                                        <v-checkbox v-model="statusCheckedFalse" @change="validateInput(item,statusCheckedFalse,1)">
                                        </v-checkbox>
                                    </div>
                                </div>
                            </template>
    
                            <template v-slot:item.priority="{item}">
                                <span v-if="item.priority=='Urgent'" class="font-weight-bold my-3 red--text" >
                                    {{ item.priority }}
                                </span>
                                <span v-if="item.priority=='Medium'" style="color: #FBB955;" class="font-weight-bold my-3 ">
                                    Mid
                                </span>
                                <span v-if="item.priority=='Low'" class="font-weight-bold my-3 green-text" >
                                    Low
                                </span>
                            </template>
                            <template v-slot:item.pic_name="{item}">
                                <span v-if="item.pic_name!=null">
                                    <!-- {{ item.pic_name }} -->
                                    Personal
                                </span>
                                <span v-else>
                                    {{ (item.role_name=='vision_admin')?"Admin":item.role_name.replaceAll('_',' ') }}
                                </span>
                            </template>
                            <template v-slot:item.task_name="{item}">
                                <a @click="redirectReadTask(item.task_id)" class="text-decoration-none">
                                {{ item.task_name}}
                                </a>
                            </template>
                            <template v-slot:item.index="{item}">
                                {{ (task.indexOf(item))+1 }}
                            </template>
                            <!-- <template v-slot:item.task_status="{item}">
                                <div v-if="loadingUpdateStatus">
                                    <v-progress-circular >
    
                                    </v-progress-circular>
                                </div>
                                <div v-else>
                                    <v-checkbox style="color:green" v-if="item.task_status=='Done'" v-model="statusCheckedTrue" disabled>
                                    </v-checkbox>
                                    <v-checkbox disabled v-else v-model="statusCheckedFalse" @change="updateTaskStatus(item,statusCheckedFalse)">
        
                                    </v-checkbox>
                                </div>
                            </template> -->
                            <template v-slot:top>
                               
                                <v-container>
                                    <v-toolbar flat class="pa-3">
                                        <v-text-field filled dense outlined clearable v-model="searchResult" label="Search">
                
                                        </v-text-field>
                                    </v-toolbar>
                                </v-container>
                            </template>
                        </v-data-table>
                    </div>
                </v-container>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import { mapState } from 'vuex'
import AConfirmation from '../common/AConfirmation.vue';

export default {
    components:{
    AConfirmation
},
    computed: mapState({
    //
    }),
    props:[
    'status'
    ],
    data: () => ({
        loadingUpdateStatus:false,
        tempTaskItem:null,
        tempTaskStatus:null,
        tempStatusChecked:null,
        statusCheckedTrue:true,
        statusCheckedFalse:false,
        tab:null,
        task:null,
        headersTask:[
            {
                text:'No.',
                value:'index',
                sortable:false,
            },
          
            {
                text:'PIC',
                value:'pic_name',
            },
            {
                text:'Action',
                value:'task_category',

            },
            {
                text:'Description',
                value:'task_action'
            },  
          
            {
                text:'Date',
                value:'created_at',
            },
            {
                text:'Id',
                value:'task_name',
            },

            {
                text:'Priority',
                value:'priority'
            },
            {
                text:'Assigned by',
                value:'assignee_name',
            },
            {
                text:'Completed by',
                value:'completed_by_name',
            }
           
        ],

        operationHeadersTask:[
            {
                text:'No.',
                value:'index',
                sortable:false,
            },
          
            {
                text:'PIC',
                value:'pic_name',
            },
            {
                text:'Action',
                value:'task_category',

            },
            {
                text:'Description',
                value:'task_action'
            },  
          
            {
                text:'Date',
                value:'created_at',
            },
            {
                text:'Id',
                value:'task_name',
            },

            {
                text:'Priority',
                value:'priority'
            },
            {
                text:'Assigned by',
                value:'assignee_name',
            },
            {
                text:'Completed by',
                value:'completed_by_name',
            },
            {
                text:'Done',
                value:'task_status',
            }
           
        ],
        userRole:null,
        isPending:false,
        searchResult:null,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
        }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getTaskByCompany"){
                this.task = resp.data;
            }
            if(resp.class=="updateTaskStatus"){
                location.reload();
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.userRole = this.$store.getters.getRole;
            if(this.status=='Complete'){
                let fetchTaskBasedOnUserCompleteAPi = this.fetchTaskBasedOnUserComplete();
                this.$api.fetch(fetchTaskBasedOnUserCompleteAPi);
            }
            
            if(this.status=='Incomplete'){
                let fetchTaskBasedOnUserIncompleteAPi = this.fetchTaskBasedOnUserIncomplete();
                this.$api.fetch(fetchTaskBasedOnUserIncompleteAPi);

            }

        },
        fetchTaskBasedOnUserIncomplete(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task/company/"+this.$router.history.current.params.id+"?status=To do";
            return tempApi;
        },
        fetchTaskBasedOnUserComplete(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task/company/"+this.$router.history.current.params.id+"?status=Done";
            return tempApi;
        },
        validateInput(taskItem,statusChecked,taskStatus){
            let allowedRoles = ["developer","operation"];
            this.loadingUpdateStatus = true;
            if(!allowedRoles.includes(this.$store.getters.getRole)){

                this.statusCheckedTrue = true;
                this.notAllowed = true;
                return 1;
            }
            this.tempTaskStatus =(taskStatus==0)?'To do':'Done';
            this.tempTaskItem = taskItem;
            this.tempStatusChecked = statusChecked;
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
        },
        cancelSubmit(){
            this.isPending = false;
            this.statusCheckedTrue=true;
            this.loadingUpdateStatus = false;
            this.isPending = false;
        },
        redirectNewTask(){
            let companyId = this.$router.history.current.params.id;
            this.$router.push({name:'PageTaskCreate',params:{id:companyId}});
        },
        redirectReadTask(taskId){
            this.$router.push({name:'PageTaskDetail',params:{id:taskId}});
        },
        updateTaskStatus(){
            this.isPending = false;
            this.statusCheckedFalse = false;
            this.statusCheckedTrue = true;
            let task = this.$_.clone(this.tempTaskItem);
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task/status/id/"+task.task_id;
            tempApi.params ={
                'status':this.tempTaskStatus,
                'userId':this.$store.getters.getUserId,
            };
           

            this.$axios.all([
                this.$api.fetch(tempApi),
            ]);
        },
        create(){

        },
    }
}
</script>