var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('AConfirmation',{attrs:{"isShow":_vm.isPending},on:{"cancel":_vm.cancelSubmit,"confirm":_vm.updateTaskStatus}}),(this.api.isLoading)?_c('v-skeleton-loader',{ref:"skeleton",attrs:{"type":"table"}}):_vm._e(),(!_vm.api.isLoading)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mx-auto my-6"},[_c('v-container',[(this.$store.getters.getRole!='operation')?_c('div',[_c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"items":_vm.task,"search":_vm.searchResult,"headers":_vm.headersTask},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.split('T')[0])+" ")]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [(item.priority=='Urgent')?_c('span',{staticClass:"font-weight-bold my-3 red--text"},[_vm._v(" "+_vm._s(item.priority)+" ")]):_vm._e(),(item.priority=='Medium')?_c('span',{staticClass:"font-weight-bold my-3 ",staticStyle:{"color":"#FBB955"}},[_vm._v(" Mid ")]):_vm._e(),(item.priority=='Low')?_c('span',{staticClass:"font-weight-bold my-3 green-text"},[_vm._v(" Low ")]):_vm._e()]}},{key:"item.pic_name",fn:function(ref){
var item = ref.item;
return [(item.pic_name!=null)?_c('span',[_vm._v(" Personal ")]):_c('span',[_vm._v(" "+_vm._s((item.role_name=='vision_admin')?"Admin":item.role_name.replaceAll('_',' '))+" ")])]}},{key:"item.task_name",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"text-decoration-none",on:{"click":function($event){return _vm.redirectReadTask(item.task_id)}}},[_vm._v(" "+_vm._s(item.task_name)+" ")])]}},{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.task.indexOf(item))+1)+" ")]}},{key:"top",fn:function(){return [_c('v-container',[_c('v-toolbar',{staticClass:"pa-3",attrs:{"flat":""}},[_c('v-text-field',{attrs:{"filled":"","dense":"","outlined":"","clearable":"","label":"Search"},model:{value:(_vm.searchResult),callback:function ($$v) {_vm.searchResult=$$v},expression:"searchResult"}})],1)],1)]},proxy:true}],null,false,3065958590)})],1):_c('div',[_c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"items":_vm.task,"search":_vm.searchResult,"headers":_vm.operationHeadersTask},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.split('T')[0])+" ")]}},{key:"item.task_status",fn:function(ref){
var item = ref.item;
return [(_vm.loadingUpdateStatus)?_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('div',[(item.task_status=='Done')?_c('div',[_c('v-checkbox',{staticStyle:{"color":"green"},attrs:{"color":"success"},on:{"change":function($event){return _vm.validateInput(item,_vm.statusCheckedTrue,0 )}},model:{value:(_vm.statusCheckedTrue),callback:function ($$v) {_vm.statusCheckedTrue=$$v},expression:"statusCheckedTrue"}})],1):_vm._e(),(item.task_status=='To do')?_c('div',[_c('v-checkbox',{on:{"change":function($event){return _vm.validateInput(item,_vm.statusCheckedFalse,1)}},model:{value:(_vm.statusCheckedFalse),callback:function ($$v) {_vm.statusCheckedFalse=$$v},expression:"statusCheckedFalse"}})],1):_vm._e()])]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [(item.priority=='Urgent')?_c('span',{staticClass:"font-weight-bold my-3 red--text"},[_vm._v(" "+_vm._s(item.priority)+" ")]):_vm._e(),(item.priority=='Medium')?_c('span',{staticClass:"font-weight-bold my-3 ",staticStyle:{"color":"#FBB955"}},[_vm._v(" Mid ")]):_vm._e(),(item.priority=='Low')?_c('span',{staticClass:"font-weight-bold my-3 green-text"},[_vm._v(" Low ")]):_vm._e()]}},{key:"item.pic_name",fn:function(ref){
var item = ref.item;
return [(item.pic_name!=null)?_c('span',[_vm._v(" Personal ")]):_c('span',[_vm._v(" "+_vm._s((item.role_name=='vision_admin')?"Admin":item.role_name.replaceAll('_',' '))+" ")])]}},{key:"item.task_name",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"text-decoration-none",on:{"click":function($event){return _vm.redirectReadTask(item.task_id)}}},[_vm._v(" "+_vm._s(item.task_name)+" ")])]}},{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.task.indexOf(item))+1)+" ")]}},{key:"top",fn:function(){return [_c('v-container',[_c('v-toolbar',{staticClass:"pa-3",attrs:{"flat":""}},[_c('v-text-field',{attrs:{"filled":"","dense":"","outlined":"","clearable":"","label":"Search"},model:{value:(_vm.searchResult),callback:function ($$v) {_vm.searchResult=$$v},expression:"searchResult"}})],1)],1)]},proxy:true}],null,false,2152477763)})],1)])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }