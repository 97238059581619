



<template>
    <div>
        <v-toolbar class="pa-4" flat>
            <v-tabs v-model="tab" >
                <!-- <v-btn color="primary" outlined to="#incomplete"> -->
                    <v-tab class="v-tab-element" to="#incomplete">
                        Incomplete
                    </v-tab>
                <!-- </v-btn> -->
                <!-- <v-btn color="primary" outlined class="mx-2 pa-0 "> -->
                    <v-tab  class="v-tab-element" to="#complete">
                        Completed
                    </v-tab>

                <!-- </v-btn> -->
            </v-tabs>
            <!-- <v-spacer>

            </v-spacer>
            <v-text-field class="mt-4" filled dense outlined clearable v-model="searchResult" label="Search">
            </v-text-field> -->
        </v-toolbar>
        <v-tabs-items v-model="tab">
            <!-- For the case of incomplete task -->
            <v-tab-item id="incomplete">
                <ComponentTaskListCompany status="Incomplete"/>
            </v-tab-item>
            <!-- For the case of complete task -->
            <v-tab-item id="complete">
                <ComponentTaskListCompany status="Complete"/>
            </v-tab-item>

        </v-tabs-items>
    </div>

</template>
<style scoped>
.v-tab-element{
    color:black;
    background-color: white;
}
.v-tab-element:active{
    background-color:#00688F !important;
    color:white !important;
    font-style:bold !important;
}
.v-tab-element:hover{
    background-color:#00688F !important;
    color:white !important;
    font-style:bold !important;
}
</style>
  
<script>
import { mapState } from 'vuex'
import ComponentTaskListCompany from '../../components/task/ComponentTaskListCompany.vue';
// import AConfirmation from '../../components/common/AConfirmation.vue';
export default {
    components:{
    ComponentTaskListCompany
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        tab:null,
        task:null,
        headersTask:[
            {
                text:'#',
                value:'index',
            },
            {
                text:'Id',
                value:'task_name',
            },
            {
                text:'Company',
                value:'name',
            },
            {
                text:'Assigned to',
                value:'pic_name',
            },  
            {
                text:'Action',
                value:'task_category',

            },
          
            // {
            //     text:'Assigned date',
            //     value:'created_at',
            // },

            // {
            //     text:'Deadline',
            //     value:'deadline_at',
            // },
            {
                text:'Priority',
                value:'priority'
            },
            {
                text:'Status',
                value:'task_status',

            },
            {
                text:'Description',
                value:'task_action'
            },  
        ],
        userRole:null,
        isPending:false,
        searchResult:null,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
        }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getTaskByStatus"){
                this.task = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            // this.userRole = this.$store.getters.getRole;
            // let fetchTaskBasedOnUserIncompleteAPi = this.fetchTaskBasedOnUserIncomplete();
            // let fetchTaskBasedOnUserCompleteApi = this.fetchTaskBasedOnUserComplete();
            // this.$axios.all([

            //     this.$api.fetch(fetchTaskBasedOnUserIncompleteAPi),
            //     this.$api.fetch(fetchTaskBasedOnUserCompleteApi),
            // ]);

        },
        fetchTaskBasedOnUserIncomplete(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task/status/"+this.$store.getters.getUserId+"?status=To do";
            return tempApi;
        },
        fetchTaskBasedOnUserComplete(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task/status/"+this.$store.getters.getUserId+"/?status=Done";
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
        },
        cancelSubmit(){
            this.isPending = true;
        },
        redirectNewTask(){
            let companyId = this.$router.history.current.params.id;
            this.$router.push({name:'PageTaskCreate',params:{id:companyId}});
        },
        redirectReadTask(taskId){
            this.$router.push({name:'PageTaskDetail',params:{id:taskId}});
        },
        create(){

        },
    }
}
</script>
